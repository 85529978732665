<template>
  <div
    ref="rootElement"
    :class="[
      'customer-profiling-filters-field-string',
      { 'customer-profiling-filters-field-string--is-error': !!error },
    ]"
  >
    <input
      ref="inputElement"
      class="customer-profiling-filters-field-string__input"
      type="text"
      :value="localFilter.value"
      @input="setFilter($event.target.value)"
      @blur="updateFilter"
    >
    <Icon
      class="customer-profiling-filters-field-string__icon"
      name="x"
      @click.stop="removeFilter"
    />
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { ref } from 'vue';
import { FilterCondition } from '@/services/helpers/filters';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    error: {
      type: String,
      default: '',
    },
  },
  emits: {
    'update:modelValue': {
      type: Object,
    },
    remove: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const localFilter = ref(cloneDeep(props.modelValue));
    const inputElement = ref(null);

    const setFilter = (newValue) => {
      localFilter.value.condition = newValue.length ? { id: FilterCondition.EQUAL } : null;
      localFilter.value.value = null;
      localFilter.value.value = newValue;
    };
    const updateFilter = () => {
      emit('update:modelValue', localFilter.value);
    };
    const removeFilter = () => {
      emit('remove', props.modelValue);
    };

    return {
      localFilter,
      inputElement,
      setFilter,
      removeFilter,
      updateFilter,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-filters-field-string {
  display: flex;
  align-self: center;
  width: 100%;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
  position: relative;

  &__input {
    color: #191414;
    border: 1px solid #F0F0F0;
    border-radius: 4px;
    padding: 0 24px 0 8px;
    width: 100%;
    height: 100%;

    &:focus {
      box-shadow: 0 0 0 1px #658A8A;
      outline: none;
    }
  }

  &__icon {
    stroke: #191414;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translate(0, -50%);
    cursor: pointer;
  }

  &--is-error {
    .customer-profiling-filters-field-string__input {
      box-shadow: 0 0 0 1px #ff2e2d;
      outline: none;
    }
  }
}
</style>
