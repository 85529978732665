<template>
  <div
    ref="rootElement"
    :class="[
      'customer-profiling-filters-field-integer',
      { 'customer-profiling-filters-field-integer--is-error': error },
    ]"
  >
    <div
      class="customer-profiling-filters-field-integer__field"
      @click="toggleMenu"
    >
      <div class="customer-profiling-filters-field-integer__field-label">
        {{ displayValue }}
      </div>
      <div class="customer-profiling-filters-field-integer__field-icons">
        <Icon
          name="chevron-selector"
        />
        <Icon
          name="x"
          @click.stop="removeFilter"
        />
      </div>
    </div>
    <div
      v-if="isMenuOpen"
      class="customer-profiling-filters-field-integer__menu"
    >
      <div
        v-for="option in conditionOptions"
        :key="option.id"
        class="customer-profiling-filters-field-integer__menu-option"
        @click="setFilterCondition(option)"
      >
        <RadioInput
          class="customer-profiling-filters-field-integer__menu-option-input"
          :checked="isConditionSelected(option)"
        />
        <span class="customer-profiling-filters-field-integer__menu-option-label">
          {{ option.label }}
        </span>
      </div>
      <div
        v-if="localFilter.condition?.id"
        class="customer-profiling-filters-field-integer__menu-control"
      >
        <input
          ref="inputElement"
          :class="[
            'customer-profiling-filters-field-integer__menu-control-input',
            { 'customer-profiling-filters-field-integer__menu-control-input--is-error': localFilterError }
          ]"
          type="text"
          :value="localFilter.value"
          @input="setFilterValue($event.target.value)"
        >
        <div
          v-if="localFilterError"
          class="customer-profiling-filters-field-integer__menu-control-error"
        >
          <Icon
            class="customer-profiling-filters-field-integer__menu-control-error-icon"
            name="exclamation"
          />
          <span class="customer-profiling-filters-field-integer__menu-control-error-message">
            {{ localFilterError }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  cloneDeep,
  split,
  drop,
  join,
  replace,
  isNil,
} from 'lodash';
import { ref, computed } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { getFilterConditions } from '@/services/helpers/filters';
import Icon from '@/components/common/Icon';
import RadioInput from '@/components/common/RadioInput';

const VALID_INTEGER_REGEX = new RegExp(/^-?\d*$/);

const isValidInteger = (value) => VALID_INTEGER_REGEX.test(value);

export default {
  components: {
    Icon,
    RadioInput,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    error: {
      type: String,
      default: '',
    },
  },
  emits: {
    'update:modelValue': {
      type: Object,
    },
    remove: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const localFilter = ref(cloneDeep(props.modelValue));
    const localFilterError = computed(() => localFilter.value.validator(localFilter.value.value));
    const displayValue = computed(() => {
      if (!props.modelValue.condition?.label) return 'N/A';
      if (isNil(props.modelValue.value)) return `${props.modelValue.condition.label}`;
      return `${props.modelValue.condition.label} ${props.modelValue.value}`;
    });
    const conditionOptions = [
      { id: '', label: 'N/A' },
      ...getFilterConditions(),
    ];

    const isConditionSelected = (filterObject) => (localFilter.value.condition?.id || '') === filterObject.id;
    const setFilterCondition = (condition) => {
      if (!condition.id) localFilter.value.value = '';
      localFilter.value.condition = condition;
    };
    const setFilterValue = (newValue) => {
      // Validate numeric characters
      if (newValue.length && !isValidInteger(newValue)) {
        const currentValue = props.modelValue.value || '';
        emit('update:modelValue', {
          ...props.modelValue,
          value: null,
        });
        emit('update:modelValue', {
          ...props.modelValue,
          value: currentValue,
        });
        return;
      }
      // Correct number input when number starts with 0
      const minuslessNewValue = replace(newValue, '-', '');
      if (minuslessNewValue.length > 1 && minuslessNewValue[0] === '0' && minuslessNewValue[1] !== '.') {
        let correctedValue = join(drop(split(minuslessNewValue, '')));
        if (newValue[0] === '-') correctedValue = `-${correctedValue}`;
        localFilter.value.value = null;
        localFilter.value.value = correctedValue;
        return;
      }
      // All good, apply value
      localFilter.value.value = newValue;
    };
    const removeFilter = () => {
      emit('remove', props.modelValue);
    };

    const rootElement = ref(null);
    const isMenuOpen = ref(false);
    const closeMenu = () => {
      if (isMenuOpen.value) {
        emit('update:modelValue', localFilter.value);
      }
      isMenuOpen.value = false;
    };
    const toggleMenu = () => {
      if (isMenuOpen.value) {
        closeMenu();
        return;
      }
      isMenuOpen.value = true;
    };

    onClickOutside(rootElement, closeMenu);

    return {
      localFilter,
      localFilterError,
      displayValue,
      conditionOptions,
      isConditionSelected,
      setFilterCondition,
      setFilterValue,
      removeFilter,
      rootElement,
      isMenuOpen,
      closeMenu,
      toggleMenu,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-filters-field-integer {
  position: relative;

  &__field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    width: 100%;
    height: 32px;
    min-height: 32px;
    max-height: 32px;
    padding: 0 8px;
    white-space: nowrap;
    border: 1px solid #F0F0F0;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: #FAFAFA;
    }

    &-label {
      font-family: 'Rubik', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #191414;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-icons {
      display: flex;
      align-items: center;
      gap: 4px;
      height: 100%;

      .icon {
        &:first-of-type {
          fill: #191414;
        }

        &:last-of-type {
          stroke: #191414;
        }
      }
    }
  }

  &__menu {
    background-color: #fff;
    border: 1px solid #F0F0F0;
    box-shadow: 0px 2px 4px 0px #19141414;
    border-radius: 4px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    min-height: 16px;
    margin-top: 4px;
    z-index: 9000;
    padding: 4px 0;

    &-option {
      display: flex;
      align-items: center;
      gap: 4px;
      width: 100%;
      height: 32px;
      min-height: 32px;
      max-height: 32px;
      padding: 0 8px;
      cursor: pointer;
      user-select: none;

      &:hover {
        background-color: #FAFAFA;
      }

      &-input {
        flex-shrink: 0;
      }

      &-label {
        color: #191414;
        font-family: 'Rubik', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    &-control {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 4px;
      width: 100%;
      padding: 4px 8px;

      &-input {
        background-color: #fff;
        width: 100%;
        height: 32px;
        min-height: 32px;
        max-height: 32px;
        padding: 0 8px;
        border: 1px solid #F0F0F0;
        border-radius: 4px;
        outline: none;

        &:not(&--is-error):focus {
          box-shadow: 0 0 0 1px #658A8A;          ;
          outline: none;
        }

        &--is-error {
          box-shadow: 0 0 0 1px #ff2e2d;          ;
          outline: none;
        }
      }

      &-error {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 10px;
        width: 100%;

        &-icon {
          flex-shrink: 0;
          background-color: #ff2e2d;
          fill: #fff;
          border-radius: 100%;
          width: 12px;
          min-width: 12px;
          max-width: 12px;
          height: 12px;
          min-height: 12px;
          max-height: 12px;
        }

        &-message {
          color: #ff2e2d;
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
        }
      }
    }
  }

  &--is-error {
    .customer-profiling-filters-field-integer__field {
      border-color: #ff2e2d;
    }
  }
}
</style>
