<template>
  <div
    ref="rootElement"
    class="customer-profiling-filters-select"
  >
    <Button
      class="customer-profiling-filters-select__button"
      @click="toggleMenu"
    >
      <Icon
        name="plus"
      />
      <span>
        Add filters
      </span>
      <Icon
        name="chevron-selector"
      />
    </Button>
    <div
      v-if="isMenuOpen"
      class="customer-profiling-filters-select__menu"
    >
      <div class="customer-profiling-filters-select__menu-header">
        <TextInput
          v-model="search"
          icon="search"
          placeholder="Search"
          small
        />
      </div>
      <div class="customer-profiling-filters-select__menu-content">
        <div
          v-for="option in filterOptions"
          :key="option.id"
          class="customer-profiling-filters-select__menu-option"
          @click="toggleFilter(option)"
        >
          <Checkbox
            class="customer-profiling-filters-select__menu-option-input"
            :model-value="isFilterToggled(option)"
            @click="toggleFilter(option)"
          />
          <span class="customer-profiling-filters-select__menu-option-label">
            {{ option.label }}
          </span>
        </div>
      </div>
      <div
        v-if="filterOptions?.length"
        class="customer-profiling-filters-select__menu-footer"
      >
        <Button
          @click="bulkToggleFilters"
        >
          {{ bulkToggleLabel }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  filter as filterArray,
  find,
  includes,
  toLower,
  every,
  negate,
} from 'lodash';
import { ref, computed } from 'vue';
import { onClickOutside } from '@vueuse/core';
import Icon from '@/components/common/Icon';
import Button from '@/components/common/Button';
import TextInput from '@/components/common/TextInput';
import Checkbox from '@/components/common/Checkbox';

export default {
  components: {
    Icon,
    Button,
    TextInput,
    Checkbox,
  },
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  emits: {
    'update:modelValue': {
      type: Array,
    },
  },
  setup(props, { emit }) {
    const rootElement = ref(null);
    const search = ref('');
    const isMenuOpen = ref(false);

    const filterOptions = computed(() => filterArray(props.options, ({ label }) => includes(toLower(label), toLower(search.value))));
    const isFilterToggled = (filter) => !!find(props.modelValue, { id: filter.id });
    const isEveryFilterToggled = computed(() => every(filterOptions.value, isFilterToggled));
    const bulkToggleLabel = computed(() => (isEveryFilterToggled.value ? 'Unselect all' : 'Select all'));

    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value;
    };
    const addFilter = (filter) => {
      const newModelValue = [...props.modelValue, filter];
      emit('update:modelValue', newModelValue);
    };
    const removeFilter = (filter) => {
      const newModelValue = filterArray(props.modelValue, ({ id }) => filter.id !== id);
      emit('update:modelValue', newModelValue);
    };
    const toggleFilter = (filter) => {
      if (isFilterToggled(filter)) {
        removeFilter(filter);
      } else {
        addFilter(filter);
      }
    };
    const bulkToggleFilters = () => {
      if (isEveryFilterToggled.value) {
        emit('update:modelValue', []);
      } else {
        const untoggledFilters = filterArray(filterOptions.value, negate(isFilterToggled));
        emit('update:modelValue', [...props.modelValue, ...untoggledFilters]);
      }
    };

    onClickOutside(rootElement, () => {
      isMenuOpen.value = false;
    });

    return {
      rootElement,
      search,
      isMenuOpen,
      filterOptions,
      bulkToggleLabel,
      toggleMenu,
      isFilterToggled,
      toggleFilter,
      bulkToggleFilters,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-filters-select {
  &__button {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__menu {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid #F0F0F0;
    box-shadow: 0px 2px 4px 0px #19141414;
    border-radius: 4px;
    position: absolute;
    bottom: 84px;
    left: 16px;
    width: calc(100% - 32px);
    max-height: 560px;
    z-index: 9000;

    &-header {
      display: flex;
      align-items: center;
      width: 100%;
      height: 48px;
      min-height: 48px;
      max-height: 48px;
      border-bottom: 1px solid #F0F0F0;
      padding: 0 12px;

      .text-field {
        width: 100%;
        height: 32px;
        min-height: 32px;
        max-height: 32px;

        .button {
          height: 32px;
          min-height: 32px;
          max-height: 32px;
        }
      }
    }

    &-content {
      flex: 1;
      width: 100%;
      height: 100%;
      overflow-y: auto;
    }

    &-option {
      display: flex;
      align-items: center;
      gap: 4px;
      width: 100%;
      height: 32px;
      min-height: 32px;
      max-height: 32px;
      padding: 0 8px;
      cursor: pointer;

      &-label {
        color: #191414;
        display: block;
        font-family: 'Rubik', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-footer {
      width: 100%;
      height: 32px;
      min-height: 32px;
      max-height: 32px;
      border-top: 1px solid #F0F0F0;

      .button {
        text-align: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 4px;
      }
    }
  }
}
</style>
